import _laxios from '../../_laxios';

module.exports = {
  namespaced: true,
  actions: {
    fetchSubscribes({ rootGetters }, { params, hasTestCookie }) {
      const { 'lang/id': langId } = rootGetters;
      Object.assign(params, { langId });

      return _laxios
        .getSubscribePageInfo
        .expandUrl({ param: hasTestCookie ? 'all' : '' })
        .request({ params })
        .then((response) => {
          if (hasTestCookie) {
            const { list, count } = response;
            const modifiedList = list.map(({
              id, active, subscribeString, channel,
            }) => ({
              id,
              active,
              searchString: subscribeString?.versions?.[subscribeString?.version],
              isOldSearchVersion: subscribeString?.version === 2,
              description: subscribeString?.full,
              channel,
            }));

            return { count, list: modifiedList };
          }

          return response;
        })
        .catch((error) => {
          console.error('subscriptions/subspage fetchSubscribe error', error.message);
        });
    },
    deleteSubscribe(_, { projectId, channelId, subsId }) {
      return _laxios
        .deleteSubscribe
        .expandUrl({ projectId, channelId, subsId })
        .request()
        .then((response) => response);
    },
  },
};
